<template>
  <b-container class="bet-list mb-5">
    <div class="px-3">
      <b-row align-v="center" class="mt-2 mb-2">
        <b-col md="3" sm="6" cols="6" class="mb-2 select_box">
          <b-form-select
            size="sm"
            v-model="customer_selected"
            :options="customers"
          ></b-form-select>
        </b-col>
        <b-col md="2" sm="6" cols="6" class="mb-2">
          <date-range
            :from="from"
            :to="to"
            opens="left"
            @date-change="dateChange"
          ></date-range>
        </b-col>
        <b-col md="3" sm="4" cols="6" class="mb-2 select_box">
          <b-form-select
            size="sm"
            v-model="game_selected"
            :options="games"
          ></b-form-select>
        </b-col>
        <b-col md="2" sm="3" cols="6" class="mb-2">
          <b-input-group size="sm" prepend="Tin sai          ">
            <b-input-group-append is-text>
              <b-form-checkbox
                :value="true"
                :unchecked-value="null"
                v-model="filterError"
              ></b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="2" sm="3" cols="12" class="mb-2">
          <b-button
            class="w-100"
            v-if="bet_selected.length == 0"
            variant="danger"
            @click="confirmDeleteSms"
            size="sm"
            >Xóa</b-button
          >
          <b-button
            class="w-100"
            v-else
            variant="danger"
            size="sm"
            @click="confirmDeleteSms"
            >Xóa ({{ bet_selected.length }})</b-button
          >
        </b-col>
      </b-row>
    </div>
    <bet-item
      @delete-one="onDeleteOne"
      @bet-selected="onBetSelect"
      ref="betListComponent"
      :data="datas_tien_xac"
      :selected="bet_selected"
      :fetchData="fetchSmsList"
      :from="from"
      :to="to"
    >
    </bet-item>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import BetItem from "@/components/bet/BetItem";
import { Utilities } from "@/utilities";
import DateRange from "@/components/DateRange";

export default {
  name: "BetList",
  components: {
    BetItem,
    "date-range": DateRange
  },
  data() {
    return {
      page: 1,
      games: [],
      game_selected: null,
      customer_selected: null,
      customers: [],
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      filterError: null,
      datas_tien_xac: [],
      bet_selected: [],
      variantOverlay: localStorage.getItem("theme")
    };
  },
  mounted() {
    this.bindPlayerOpts(this.players);
    this.bindGameOpts(this.game);
  },
  created() {
    this.bindDate();
  },
  watch: {
    players: function(players) {
      this.bindPlayerOpts(players);
    },
    game: function(games) {
      this.bindGameOpts(games);
    },
    game_selected: async function(newGame) {
      console.log(newGame);
      await this.$nextTick();
      this.resetPage();
    },
    filterError: function(errVal) {
      console.log(errVal);
      this.resetPage();
    },
    customer_selected: async function(newCustomer) {
      console.log(newCustomer);
      await this.$nextTick();
      this.resetPage();
    }
  },
  computed: {
    ...mapGetters(["players", "game"]),
    deleteAllWarning: function() {
      let vm = this;
      let date = vm.from == vm.to ? vm.from : vm.from + " -> " + vm.to;
      let text = "Bạn chắc chắn muốn xóa toàn bộ cược ngày " + date;
      let game = this.game_selected
        ? (
            "miền" +
            this.games.filter(function(e) {
              return e.value == vm.game_selected;
            })[0].text
          ).toLowerCase()
        : "";
      let player = this.customer_selected
        ? "của khách " +
          this.customers.filter(function(e) {
            return e.value == vm.customer_selected;
          })[0].text
        : "";

      return [text, game, player, "?"].join(" ");
    },
    deleteSelectedWarning: function() {
      let text =
        "Bạn chắc chắn muốn xóa " + this.bet_selected.length + " cược đã chọn?";
      return text;
    }
  },
  methods: {
    bindDate() {
      this.from = this.$route.query.from
        ? moment(this.$route.query.from, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
      this.to = this.$route.query.to
        ? moment(this.$route.query.to, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    },
    bindPlayerOpts(players) {
      let customers = players.map(function(e) {
        return { value: e.id, text: e.name };
      });
      customers.unshift({ value: null, text: "Tất cả khách" });
      this.customers = customers;
    },
    bindGameOpts(games) {
      let g = Object.values(games).map(function(e) {
        return { value: e[0].id, text: e[0].alias.replace("Miền", "") };
      });
      g.unshift({ value: null, text: "Tất cả miền" });
      this.games = g;
    },
    resetPage() {
      this.page = 1;
      this.datas_tien_xac = [];
      this.$refs["betListComponent"].resetList();
    },
    async fetchSmsList(state) {
      let vm = this;
      let data = {
        from: vm.from,
        to: vm.to,
        page: vm.page
      };
      if (vm.game_selected) data.game = vm.game_selected;
      if (vm.customer_selected) data.player = vm.customer_selected;
      if (vm.filterError) data.error = vm.filterError;

      let res = await vm.$http.get("bets/sms", { params: data });
      if (res.data.code == 0) {
        if (res.data.data.length == 0) {
          state.complete();
          return;
        }

        let d = res.data.data.map(function(e, idx) {
          let status = e.status;
          if (idx == 0 && vm.page == 1) {
            status = status == "error" ? "error" : "new";
          }

          return {
            id: e.id,
            status: status,
            name: e.name,
            sms: e.sms,
            game_id: e.game_id,
            t2c: Utilities.currencyReport(e["2c"]),
            t3c: Utilities.currencyReport(e["3c"]),
            t4c: Utilities.currencyReport(e["4c"]),
            tdadx: Utilities.currencyReport(e.dadx),
            updated_at: moment(e.updated_at).format("hh:mm:ss")
          };
        });
        d = [...vm.datas_tien_xac, ...d];

        vm.datas_tien_xac = d;
        vm.page++;

        if (res.data.data.length < 20) {
          state.complete();
        } else {
          state.loaded();
        }
      }
    },
    onBetSelect(betId) {
      let selecteds = [...this.bet_selected];
      if (selecteds.indexOf(betId) >= 0) {
        selecteds = selecteds.filter(function(e) {
          return e != betId;
        });
      } else {
        selecteds.push(betId);
      }

      this.bet_selected = selecteds;
    },
    onDeleteOne(betId) {
      this.bet_selected = [betId];
      this.confirmDeleteSms();
    },
    confirmDeleteSms() {
      let vm = this;

      let msg =
        vm.bet_selected.length == 0
          ? vm.deleteAllWarning
          : vm.deleteSelectedWarning;

      let filter = {
        from: this.from,
        to: this.to
      };
      if (this.customer_selected) {
        filter.player_id = this.customer_selected;
      }
      if (this.game_selected) {
        filter.game_id = this.game_selected;
      }

      vm.$bvModal
        .msgBoxConfirm(msg, {
          title: "Xác nhận xóa sms",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Xóa",
          cancelTitle: "Không",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true
        })
        .then(async value => {
          if (value) {
            let res =
              vm.bet_selected.length == 0
                ? await this.$http.post("bets/deleteFilter", filter)
                : await vm.$http.delete("bets/" + vm.bet_selected.join(","));
            if (res.data.code == 0) {
              vm.resetPage();
              vm.bet_selected = [];
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async dateChange(date) {
      this.from = date.from;
      this.to = date.to;

      await this.$nextTick();

      if (
        this.$router.history.current.query.from == this.from &&
        this.$router.history.current.query.to == this.to
      )
        return;

      this.resetPage();
      this.$router.replace({
        name: this.$router.history.current.name,
        params: this.$router.history.current.params,
        query: {
          ...this.$router.history.current.query,
          from: this.from,
          to: this.to
        }
      });
    }
  }
};
</script>
