<template>
  <div>
    <b-list-group>
      <b-list-group-item
        class="px-3 d-flex d-flex align-items-center_ bet-item"
        :class="getStatusVariance(item.status)"
        v-for="(item, indexItem) in data"
        :key="indexItem"
      >
        <div>
          <b-avatar
            v-if="selected.indexOf(item.id) < 0"
            button
            @click="selectedBet(item)"
            class="mr-2 mt-2"
            :variant="getStatusVariance(item.status)"
            :text="(indexItem + 1).toString()"
          ></b-avatar>
          <b-avatar
            v-else
            button
            @click="selectedBet(item)"
            icon="check"
            class="mr-2"
            variant="danger"
            :text="(indexItem + 1).toString()"
          ></b-avatar>
          <b-icon
            @click="deleteOne(item)"
            icon="trash"
            variant="secondary"
            font-scale="1"
            class="delete-icon"
          >
          </b-icon>
        </div>
        <b-link
          class="d-flex link"
          :to="{
            name: 'betSms',
            query: { smsid: item.id, origin: 'betList', from: from, to: to }
          }"
        >
          <div class="mr-auto info pr-3">
            <div class="player-name">
              <span>{{
                item.name.length > 16
                  ? item.name.substring(0, 16) + "..."
                  : item.name
              }}</span>
            </div>
            <small class="sms"
              >{{ getGameCode(item.game_id) }} -
              {{
                item.sms.length > 55
                  ? item.sms.substring(0, 55) + "..."
                  : item.sms
              }}</small
            >
            <small class="date">{{ item.updated_at }}</small>
          </div>
          <div class="card-profile-stats d-flex">
            <div class="text-left">
              <span class="description">2c: {{ item.t2c }}</span>
              <span class="description">3c: {{ item.t3c }}</span>
              <span class="description">4c: {{ item.t4c }}</span>
              <span class="description">dadx: {{ item.tdadx }}</span>
            </div>
          </div>
        </b-link>
      </b-list-group-item>
    </b-list-group>
    <infinite-loading :identifier="infiniteId" @infinite="handleInfinity">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>
<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "bet-item",
  props: ["data", "fetchData", "selected", "from", "to"],
  data() {
    return {
      infiniteId: +new Date()
    };
  },
  methods: {
    handleInfinity($state) {
      if (this.fetchData) this.fetchData($state);
    },
    resetList() {
      this.infiniteId++;
    },
    getStatusVariance(status) {
      switch (status) {
        case "new":
          return "primary";
        case "done":
          return "success";
      }

      return "danger";
    },
    selectedBet(bet) {
      this.$emit("bet-selected", bet.id);
    },
    deleteOne(bet) {
      this.$emit("delete-one", bet.id);
    },
    getGameCode(game_id) {
      console.log(game_id);
      switch (game_id) {
        case 1:
          return "MB";
        case 2:
          return "MT";
        case 3:
          return "MN";
      }
    }
  },
  components: {
    InfiniteLoading
  }
};
</script>
<style>
.list-group {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}
.bet-item.list-group-item {
  padding: 5px;
  margin-bottom: 1px;
}
.bet-item .sms {
  word-break: break-all;
}
.bet-item .date {
  position: absolute;
  bottom: 8px;
  left: 50px;
  font-style: italic;
  font-size: 0.7rem;
}
.bet-item .card-profile-stats > div .heading,
.bet-item .card-profile-stats > div .description {
  font-size: 0.9rem;
  text-transform: lowercase;
  display: block;
}
.bet-item .b-avatar {
  width: 1.5rem;
  height: 1.5rem;
  font-size: medium;
  line-height: 1.4;
}
.bet-item .link {
  width: 100%;
}
.bet-item .card-profile-stats {
  padding: 0px;
  min-width: 100px;
}
.card-profile-stats > div {
  padding: 0px;
  text-align: center;
  margin-right: 1rem;
  text-align: end;
  min-width: 70px;
}
.bet-item .card-profile-stats > div:last-child {
  margin-right: 0;
}

.bet-item .info .player-name {
  text-align: start;
  margin: 0px;
}
.bet-item .info small {
  float: left;
  margin-left: 0px;
  text-align: left;
}
.bet-item .b-avatar .b-avatar-custom,
.bet-item .b-avatar .b-avatar-text,
.bet-item .b-avatar .b-avatar-img {
  align-items: flex-end;
}
.bet-item .delete-icon {
  position: absolute;
  bottom: 10px;
  left: 14px;
  font-size: 120%;
}
</style>
